<template>
  <div class="courseDetails">
    <Header/>
    <div class="courseDetails_box">
      <div class="content">
        <div class="videos" id="player"></div>
        <div class="rightDetail">
          <div class="rightDetailWrap">
            <div class="top">
              <div class="name">{{ courseInfo?.courseName }}</div>
              <div class="tags">
                <!--                <div class="item">主讲老师：超哥</div>-->
                <div class="item">课程代码: {{ courseInfo?.courseNo }}</div>
                <div class="item">课程时长: {{ courseInfo?.courseTime }}</div>
              </div>
              <div class="dashedLine"></div>

              <!--              <div class="money">-->
              <!--                <img src="https://static.dasdt.cn/images/money.png">-->
              <!--                <span>报名课程可赠送300.00元助学金</span>-->
              <!--              </div>-->
              <div class="dashedLine"></div>
              <div class="service">
                <div class="item">
                  <img src="https://static.dasdt.cn/images/server.png">
                  <span>课程答疑</span>
                </div>
                <div class="item">
                  <img src="https://static.dasdt.cn/images/server.png">
                  <span>刷题测评</span>
                </div>
                <div class="item">
                  <img src="https://static.dasdt.cn/images/server.png">
                  <span>考前辅导</span>
                </div>
              </div>
              <div class="priceWrap">
                <div class="item">
                  <div class="price"><span>￥</span>{{ courseInfo?.recordsPrice }}</div>
                  <div class="bottoms">
                    <div class="channel">录播</div>
                    <div class="name">统一售价</div>
                  </div>
                </div>
                <div class="item">
                  <div class="price"><span>￥</span>{{ courseInfo?.salePrice }}</div>
                  <div class="bottoms">
                    <div class="channel">直播</div>
                    <div class="name">统一售价</div>
                  </div>
                </div>
                <div class="item">
                  <div class="price"><span>￥</span>{{ courseInfo?.scholar }}</div>
                  <div class="bottoms">
                    <div class="channel">面授</div>
                    <div class="name">具体询问顾问老师</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <!--              <div class="price"><span>￥</span>4800<span>元</span></div>-->
              <div class="btns">

                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover">
                  <div class="shareList">
                    <div class="item" style="border-bottom: 1px solid #E6E6E6"
                         @click="copyCurrentPageLink(courseInfo?.courseId)">
                      <img src="https://static.dasdt.cn/images/link3.png">
                      <span>复制分享链接</span>
                    </div>
                    <!--                    <div class="item">-->
                    <!--                      <img src="https://static.dasdt.cn/images/weibo.png">-->
                    <!--                      <span>分享到微博</span>-->
                    <!--                    </div>-->
                    <!--                    <div class="item">-->
                    <!--                      <img src="https://static.dasdt.cn/images/qq.png">-->
                    <!--                      <span>分享到QQ</span>-->
                    <!--                    </div>-->
                    <!--                    <div class="item">-->
                    <!--                      <img src="https://static.dasdt.cn/images/douban.png">-->
                    <!--                      <span>分享到豆瓣</span>-->
                    <!--                    </div>-->
                  </div>
                  <div slot="reference" class="haveImg">
                    <img src="https://static.dasdt.cn/images/share.png">
                    <span>分享</span>
                  </div>
                </el-popover>
                <!--                <div class="haveImg" @click="eventCollection" v-show="isShow">-->
                <!--                  <img src="https://static.dasdt.cn/images/star2.png">-->
                <!--                  <span>收藏</span>-->
                <!--                </div>-->
                <!--                <div class="haveImg" style="background: #fff;border: 1px solid #ADADAD" @click="eventCollection"-->
                <!--                     v-show="!isShow">-->
                <!--                  <img src="https://static.dasdt.cn/images/star5.png">-->
                <!--                  <span style="color: #ADADAD">已收藏</span>-->
                <!--                </div>-->
                <!--                <div class="application" @click="dialogVisible = true">立即报名</div>-->
                <a v-if="isLogin" href="javascript:void(0)" onclick="openJesongChatByGroup(13299, 88804);return false;">
                  <div class="application">立即报名</div>
                </a>
                <div class="application" v-else @click="isShow2 = true">立即报名</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="bottomWrap">
        <div class="courseInfo">
          <div class="courseInfoWrap">
            <el-tabs v-model="activeName">
              <el-tab-pane label="课程介绍" name="1">
                <div ref="pdfCanvas" id="pdf-renderer"></div>
              </el-tab-pane>
              <el-tab-pane label="试看课程" name="2">
                <div class="coresList">
                  <div class="item" v-for="(item, index) in coresList" :key="index">
                    <div class="index">{{ index + 1 }}</div>
                    <div class="nameOrTimer">
                      <div class="name">{{ item.title }}</div>
                      <!--                      <div class="timer">3分钟</div>-->
                    </div>
                    <div class="btn" @click="switchPlay(item.polyvVid)">
                      <img src="https://static.dasdt.cn/images/right3.png">
                      <span>免费学习</span>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="授课计划" name="3">
                <div class="coresList">
                  <div class="item" v-for="(item, index) in planList" :key="index">
                    <div class="index">{{ index + 1 }}</div>
                    <div class="nameOrTimer">
                      <div class="name" style="cursor: pointer">{{ item.courseName }}</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="考试认证" name="4">
                <el-table
                    :data="examList"
                    :headerCellStyle="{'background-color': '#F5F6F6','color': '#333333','font-size':'16px'}"
                    style="width: 100%">
                  <el-table-column
                      prop="courseNo"
                      label="代码"
                      width="100">
                  </el-table-column>
                  <el-table-column
                      prop="courseName"
                      label="考试名称"
                      width="280">
                  </el-table-column>
                  <el-table-column
                      prop="isNeed"
                      label="必考">
                    <template v-slot="{row}">
                      <img v-if="row.isNeed === '0'" style="width: 22px;height: 16px"
                           src="https://static.dasdt.cn/images/CheckNumber.png">
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="isNeed"
                      label="选考">
                    <template v-slot="{row}">
                      <img v-if="row.isNeed === '1'" style="width: 22px;height: 16px"
                           src="https://static.dasdt.cn/images/CheckNumber.png">
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="isNeed"
                      label="证书">
                    <template v-slot="{row}">
                      <img style="width: 103px;height: 70px" :src="row.img">
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="recommend">
          <!--          <div class="teacherList">-->
          <!--            <div class="teacherWrap">-->
          <!--              <div class="title">授课老师</div>-->
          <!--              <div class="list">-->
          <!--                <div class="item">-->
          <!--                  <img src="https://static.dasdt.cn/images/teacher.png">-->
          <!--                  <div class="right">-->
          <!--                    <div class="name">袁老师</div>-->
          <!--                    <div class="desc">20年网络工作经验, 17年授课讲师 经验。擅长信息安全...</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="item">-->
          <!--                  <img src="https://static.dasdt.cn/images/teacher.png">-->
          <!--                  <div class="right">-->
          <!--                    <div class="name">袁老师</div>-->
          <!--                    <div class="desc">20年网络工作经验, 17年授课讲师 经验。擅长信息安全...</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="item">-->
          <!--                  <img src="https://static.dasdt.cn/images/teacher.png">-->
          <!--                  <div class="right">-->
          <!--                    <div class="name">袁老师</div>-->
          <!--                    <div class="desc">20年网络工作经验, 17年授课讲师 经验。擅长信息安全...</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->
          <div class="teacherList">
            <div class="teacherWrap">
              <div class="title">推荐课程</div>
              <div class="courseList">
                <div class="items" v-for="(item,index) in curriculumList" :key="index">
                  <curriculumInfo :info="item"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <floatRight/>
    <Footer/>
    <el-dialog
        title="请填写报名信息"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose">
      <div class="dialogWrap">
        <div class="forms">
          <div class="item">
            <div class="title">姓名</div>
            <div class="inputWrap">
              <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </div>
          </div>
          <div class="item">
            <div class="title">手机号</div>
            <div class="inputWrap">
              <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
            </div>
          </div>
          <div class="item tarea" style="flex-direction: column;align-items: flex-start;height: auto;">
            <div class="title" style="margin: 16px 0">备注</div>
            <div class="inputWrap" style="height: auto">
              <el-input type="textarea" :rows="4" v-model="form.desc" placeholder="请输入备注"></el-input>
            </div>
          </div>
        </div>
        <div class="submit" @click="dialogVisible2 = true ">提交报名</div>
      </div>

    </el-dialog>
    <el-dialog
        title="提交成功"
        :visible.sync="dialogVisible2"
        width="400px"
        :before-close="handleClose2">
      <div class="dialogWrap">
        <img src="https://static.dasdt.cn/images/success.png">
        <div class="success">恭喜您，报名成功</div>
        <div class="desc">稍后客服会跟您沟通请注意接听电话</div>
        <div class="submit">查看报名</div>
      </div>

    </el-dialog>
    <logins v-model="isShow2"/>

  </div>
</template>
<script>
// import * as pdfjsLib from 'pdfjs-dist/webpack';
import Header from "@/components/headers/index.vue";
import curriculumInfo from "@/views/home/curriculumInfo.vue";
import {getCourseDetails, getPolyvToken, planCourseNew, records, videos} from "@/api/course";
import floatRight from "@/components/floatRight/vue.vue";
import Footer from "@/components/footer/index.vue";
import {memberCollection} from "@/api/position";
// import {getToken} from "@/utils/auth";
import logins from "@/components/login/index.vue";

const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.min");
export default {
  components: {logins, Footer, floatRight, curriculumInfo, Header},
  data() {
    return {
      isShow2: false,
      isShow: true,
      form: {
        name: '',
        phone: '',
        desc: ''
      },
      dialogVisible: false,
      dialogVisible2: false,
      activeName: '1',
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: 'cde39521e14c94ee782853f40dbd7a35_c',
      curriculumList: [],
      canvas: null,
      player: null,
      coresList: [],
      courseInfo: {},
      examList: [],
      pagination: {
        number: 1,
        limit: 12
      },
      planList: [],
      id: ''
    }
  },
  created() {
    scrollTo(0, 0)
    this.loadPlayerScript()
    this.records()
    this.planCourseNew()
  },
  computed: {
    isLogin() {
      return this.$store.getters.token !== '0';
    }
  },
  mounted() {
    this.getCourseDetails()
    this.id = this.$route.params.id
  },
  methods: {
    // 课程列表
    async records() {
      const res = await records({firstClassifyId: 1}, this.pagination)
      this.curriculumList = res.data
    },
    // 复制链接
    copyCurrentPageLink() {
      let url = window.location.href;
      let inputNode = document.createElement("input"); // 创建input
      inputNode.value = url; // 赋值给 input 值
      document.body.appendChild(inputNode); // 插入进去
      inputNode.select(); // 选择对象
      document.execCommand("Copy"); // 原生调用执行浏览器复制命令
      inputNode.className = "oInput";
      inputNode.style.display = "none"; // 隐藏
      this.$message({
        message: '复制成功',
        type: 'success'
      });

    },
    //收藏
    async eventCollection() {

      if (this.isShow) {
        const {data} = await memberCollection({type: 'CURRICULUM', courseId: this.$route.query.id})
        console.log(data)
        this.$message({
          message: '收藏成功',
          type: 'success'
        });
        this.isShow = !this.isShow

      } else {
        console.log(this.isShow)
      }
    },
    // 获取试看课程列表
    async getVideos(id) {
      const {data} = await videos(id)
      console.log(data)
      this.coresList = data[0].child
    },
    // 获取开课计划
    async planCourseNew() {
      const {data} = await planCourseNew({}, {number: 1, size: 12})
      console.log(data)
      this.planList = data[0].coursePlanList
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    async getCourseDetails() {
      const res = await getCourseDetails(this.$route.params.id)
      this.courseInfo = res.data[0].course
      await this.getVideos(res.data[0].course.courseNo)
      this.examList = res.data[0].examList
      await this.renderPage(res.data[0].course.outlinePdf)
      await this.getPolyvToken(res.data[0].course.polyvId)
    },
    // 加载 PDF 文件
    renderPage(id) {
      PDFJS.getDocument(id).promise.then((pdfDoc_) => {

        for (let pageNum = 1; pageNum <= pdfDoc_.numPages; pageNum++) {
          pdfDoc_.getPage(pageNum).then(function (page) {
            const width = page.view[2]
            var scale = 1.0; // 缩放页面以适应容器大小
            scale = width > 600 ? 0.8 : 1.2
            var viewport = page.getViewport({
              scale: scale
            });

            // 准备用于渲染的canvas元素
            var canvas = document.createElement('canvas');
            canvas.id = 'pdf-page-' + pageNum; // 为每个canvas设置唯一ID
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // 将canvas添加到HTML容器中
            document.getElementById('pdf-renderer').appendChild(canvas);

            // 使用PDF页面的数据渲染canvas
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
            });
          });
        }
      });
    },
    // 视频文件
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        // callback()
      }
    },
    async getPolyvToken(polyVid) {
      const polyvPlayer = window.polyvPlayer;
      const res = await getPolyvToken(polyVid)
      let PolyvToken = res.data[0]
      this.player = polyvPlayer({
        wrap: '#player',
        width: '100%', // 播放器宽，支持像素值和百分比两种类型，默认值为'100%'
        height: '100%', // 播放器高，支持像素值和百分比两种类型，默认值为'auto'
        vid: polyVid,
        playsafe: PolyvToken.token,
        //playsafeUrl:'https://myDomain.com/token', // 业务方自定义的获取播放凭证接口URL，与playsafe参数二选一
        ts: PolyvToken.ts,
        sign: PolyvToken.sign
      });
    },
    switchPlay(id) {
      console.log(id, this.player)
      this.getPolyvToken(id)
      this.player.switchMain(id, function (err) {
        if (!err) {

          console.log('Switched to new video successfully.');
        } else {
          console.error('Failed to switch video:', err);
        }
      });
    },
  },

  destroyed() {
    if (this.player) {
      this.player.destroy()
    }
  }
}
</script>
<style lang="scss">
.shareList {
  display: flex;
  flex-direction: column;

  .item {
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 6px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.courseDetails {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;

  .el-table tr {
    background-color: #FAFAFA;
    border: none !important;
    color: #555555;
    font-size: 16px;
  }

  .el-table__body-wrapper {
    border-bottom: none;
  }

  .el-dialog__title {
    font-weight: 500;
  }

  .dialogWrap {
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      margin-bottom: 50px;
      font-size: 14px;
      font-weight: 400;
      color: #808080;
    }

    .success {
      margin: 16px 0;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }

    .submit {
      width: 100%;
      cursor: pointer;
      margin-top: 30px;
      height: 44px;
      background: #3A76FF;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
    }

    .forms {
      display: flex;
      flex-direction: column;

      .item:not(:last-child) {
        border-bottom: 1px solid #E6E6E6;

      }

      .tarea {
        .el-textarea__inner {
          width: 350px !important;
          border: none !important;
          background: #F3F3F3 !important;
        }
      }

      .item {
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #333333;
          font-size: 14px;
          font-weight: 600;
        }

        .inputWrap {
          height: 26px;

          .el-input__inner {
            height: 26px;
            border: none !important;
            text-align: right;
          }
        }
      }
    }
  }


  .courseDetails_box {
    position: relative;
    z-index: 9;
    margin: 72px auto 24px auto;
    width: 1200px;

    .bottomWrap {
      margin: 24px auto;
      display: flex;
      gap: 16px;

      .recommend {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .teacherList {
          border: 1px solid #ffffff;
          border-radius: 8px;
          width: 320px;
          background: #ffffff;

          .teacherWrap {
            padding: 16px;

            .title {
              color: #333333;
              font-size: 18px;
              font-weight: 500;
            }

            .courseList {
              margin-top: 20px;
              display: flex;
              flex-direction: column;
              gap: 16px;

              .items {
                height: 294px;
                border-radius: 8px;
                border: 1px solid #E6E6E6;
                background: #FFFFFF;
                overflow: hidden;
              }
            }

            .list {
              margin-top: 22px;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .item {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                  width: 60px;
                  height: 60px;
                }

                .right {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  .name {
                    color: #141414;
                    font-size: 16px;
                    font-weight: 400;
                  }

                  .desc {
                    color: #999999;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }

      }

      .courseInfo {
        width: 864px;
        border-radius: 8px;
        height: auto;
        background: #ffffff;

        .courseInfoWrap {
          min-height: 500px;
          padding: 16px;

          .coresList {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 65px;
              border-bottom: 1px solid #DDDDDD;

              .index {
                color: #808080;
                font-size: 15px;
                font-weight: 400;
              }

              .btn {
                border: 1px solid #3A76FF;
                width: 90px;
                height: 26px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                cursor: pointer;

                img {
                  width: 6px;
                  height: 6.31px;
                }

                span {
                  color: #3A76FF;
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .nameOrTimer {
                flex: 1;
                margin-left: 18px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .name {
                  color: #333333;
                  font-size: 16px;
                  font-weight: 500;
                }

                .timer {
                  color: #808080;
                  font-size: 11px;
                  font-weight: 400;
                }
              }
            }
          }

          .el-tabs__nav-wrap::after {
            height: 1px !important;
            background-color: rgba($color: #1F1F1F, $alpha: 0.1) !important;
          }

          .el-tabs__active-bar {
            background-color: #185BE0 !important;

          }

          .el-tabs__item.is-active {
            color: #185BE0 !important;
          }

          .el-tabs__item {
            &:hover {
              color: #185BE0 !important;

            }
          }
        }
      }
    }

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      height: 368px;

      .videos {
        height: 100%;
        width: 636.25px;
        background: #ffffff;
      }

      .rightDetail {
        height: 100%;
        width: 564px;
        background: #ffffff;
        border-radius: 0 8px 8px 0;

        .rightDetailWrap {
          height: 100%;
          padding: 20px;
          display: flex;
          //flex-direction: column;
          align-content: space-between;
          flex-wrap: wrap;
        }

        .bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: end;

          .btns {
            margin-top: 16px;
            display: flex;
            align-items: center;
            gap: 14px;

            .application {
              background: #3A76FF;
              border-radius: 4px;
              width: 140px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              color: #ffffff;
              font-weight: 400;
              cursor: pointer;
            }

            .haveImg {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              border: 1px solid #3A76FF;
              border-radius: 4px;
              width: 80px;
              height: 38px;

              img {
                width: 14px;
                height: 14px;
              }

              span {
                color: #3A76FF;
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .price {
            color: #FD5056;
            font-weight: 600;
            font-size: 24px;

            span {
              font-size: 14px;
            }
          }
        }

        .top {
          width: 100%;

          .service {
            display: flex;
            align-items: center;
            gap: 24px;

            .item {
              display: flex;
              align-items: center;
              gap: 4px;

              img {
                width: 14px;
                height: 14px;
              }

              span {
                color: #555555;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }

          .priceWrap {
            margin-top: 14px;
            //width: 524px;
            height: 102px;
            padding-left: 20px;
            //padding: 26px 20px;
            background: #FFF9ED;
            border: 1px solid #FFE8B9;
            border-radius: 8px;
            display: flex;
            align-items: center;
            //justify-content: center;
            gap: 14px;

            .item {
              min-width: 130px;
              height: 50px;
              display: flex;
              flex-direction: column;

              .price {
                color: #FD5056;
                font-size: 24px;
                font-weight: 600;

                span {
                  font-size: 14px;
                }
              }

              .bottoms {
                display: flex;
                align-items: center;
                gap: 4px;

                .channel {
                  background: linear-gradient(to top right, #FF8100, #FFD300);
                  width: 40px;
                  height: 18px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 600;
                }

                .name {
                  color: #808080;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }

          .dashedLine {
            margin: 10px 0;
            border-bottom: 1px dashed #ADADAD;
          }

          .money {
            display: flex;
            align-items: center;
            gap: 4px;

            img {
              width: 16px;
              height: 16px;
            }

            span {
              color: #555555;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .name {
            color: #222222;
            font-weight: 500;
            font-size: 20px;
          }

          .tags {
            margin-top: 16px;
            display: flex;
            align-items: center;
            gap: 8px;

            .item {
              padding: 8px 10px;
              border-radius: 4px;
              background: rgba($color: #3A76FF, $alpha: 0.1);
              color: #3A76FF;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

      }
    }
  }
}
</style>
